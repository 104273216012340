/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Italic.ttf to have the same
// metrics as calibrii.ttf.
const CalibriItalicFactors = [
  1.3877, 1, 0.8675, 0.76318, 0.76318, 0.8675, 0.8675, 0.8675, 0.8675, 0.8675,
  0.8675, 0.8675, 0.8675, 0.8675, 0.8675, 0.8675, 0.8675, 0.81552, 0.81552,
  0.72346, 0.72346, 0.72346, 0.72346, 0.72346, 0.72346, 0.77818, 0.85193,
  0.85193, 0.86477, 0.84134, 0.73206, 0.73206, 0.73206, 0.73206, 0.73206,
  0.73206, 0.73206, 0.73206, 0.73206, 0.86698, 0.73206, 0.73206, 0.6192,
  0.86275, 0.7363, 0.86477, 0.91133, 0.7522, 0.81105, 0.7286, 0.81105, 0.81105,
  1, 0.81105, 0.86275, 1, 0.99862, 0.99862, 1, 0.90872, 0.86275, 0.90685,
  0.77896, 0.90685, 0.90685, 0.90685, 0.90685, 0.90685, 0.90685, 0.90685,
  0.90685, 0.90685, 0.90685, 0.64824, 0.90685, 0.6377, 0.6377, 0.77892, 0.77892,
  1, 0.75593, 0.75593, 0.85871, 0.76032, 1, 0.98156, 0.77261, 1.02638, 1.02638,
  0.89249, 0.89249, 0.89249, 1, 0.89249, 0.89249, 0.84118, 0.8667, 0.84118,
  0.84118, 0.84118, 0.84118, 0.84118, 0.84118, 0.84118, 0.87291, 0.85696,
  0.84118, 0.82411, 0.84557, 0.84557, 0.84118, 0.77452, 0.90782, 0.85984, 0.903,
  0.85374, 0.75186, 0.75186, 0.75186, 1, 0.77452, 0.67789, 0.70572, 0.70572,
  0.70572, 0.70572, 1, 1, 1, 1, 1, 0.70572, 0.8, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.67789, 0.67789,
  0.67789, 0.67789, 1, 0.76466, 0.79776, 0.79776, 0.79776, 0.79776, 1, 0.84369,
  0.77452, 0.88844, 0.88844, 0.88844, 0.88844, 0.88844, 0.88844, 0.88844,
  0.88844, 0.88844, 0.7306, 0.7306, 0.56321, 0.88844, 0.88844, 0.85066, 0.94309,
  0.94309, 0.94309, 0.94309, 0.94309, 0.77818, 0.75828, 0.7306, 0.7306, 0.7306,
  0.7306, 0.7306, 0.76659, 0.76659, 0.76659, 0.76659, 0.76659, 0.9245, 0.9245,
  0.9245, 0.9245, 0.87683, 1, 0.9245, 0.84843, 0.84843, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9245, 0.99058, 0.99058, 0.9245,
  1.02269, 0.90861, 0.9245, 0.90747, 0.9245, 0.9245, 1.28809, 1.81055, 0.90572,
  0.90749, 1.81055, 1.55469, 0.94434, 1.06139, 0.85284, 1.2798, 0.88071, 0.9245,
  0.9245, 1.38313, 1.77256, 0.92393, 1, 0.94152, 0.94152, 1.10369, 1.10369,
  1.1437, 1, 1.91729, 1.42259, 0.83203, 0.83203, 1.18622, 1, 1, 0.83203,
  0.83203, 0.83203, 0.83203, 0.92229, 0.89552, 0.78086, 0.91027, 1.18622, 1, 1,
  0.96309, 0.89807, 1, 1, 1.13254, 0.89552, 1, 0.9245, 0.89552, 0.89552, 0.8875,
  0.99034, 0.84737, 0.942, 1, 1.17889, 1, 1.48387, 0.90747, 1, 1, 0.91133,
  0.67009, 0.82601, 0.85865, 0.85865, 0.85865, 0.85865, 0.85865, 0.85865,
  0.85865, 0.85865, 0.91133, 1, 0.68994, 0.85865, 0.90527, 0.89552, 0.9245,
  0.85865, 1.03667, 1.03667, 0.85284, 0.85284, 1.23108, 0.94635, 0.94635,
  0.94469, 1.17223, 1.11523, 0.97801, 1.09842, 1, 1, 1, 0.91133, 0.84426, 1,
  0.54873, 0.91133, 1, 0.82616, 0.9245, 0.8916, 0.9245, 0.9245, 1, 0.9245,
  0.86331, 0.8739, 1, 0.85284, 0.90747, 0.92098, 0.92098, 1.0176, 1.0176,
  0.9245, 0.93591, 0.9245, 1, 1.44947, 1.40909, 1, 1.03297, 0.82601, 0.82601,
  0.82601, 0.82601, 0.82601, 1.05611, 0.82601, 1.19658, 1.33512, 0.8941, 0.8941,
  0.97622, 1, 1, 1, 1.03297, 1.23516, 1.23516, 1.23516, 1.23516, 0.82601,
  1.07692, 1.07692, 0.90918, 0.90918, 1, 0.90918, 1.03297, 1.03297, 0.94048,
  0.9375, 1, 0.93407, 0.85284, 0.90747, 1, 0.91133, 0.85284, 1.09231, 1.0336,
  1.11429, 1, 0.94959, 0.71353, 1, 1, 0.85284, 1, 0.98217, 0.85284, 1, 1,
  0.9245, 0.9245, 0.92534, 0.9245, 1, 0.91133, 1, 0.90747, 0.9245, 0.89746,
  0.89552, 0.92274, 0.92274, 0.92274, 0.92274, 0.92274, 0.86291, 0.93695,
  0.92274, 0.92274, 0.92274, 0.89404, 0.89404, 0.92274, 0.92274, 0.91133,
  0.79801, 0.80504, 0.76288, 0.99862, 1.16359, 1.15642, 0.69825, 0.86651,
  0.86651, 0.92274, 0.9245, 1.09091, 0.91056, 0.91056, 1.07806, 0.80395,
  0.90861, 1.03809, 0.83437, 1.00225, 0.82507, 0.85284, 0.90747, 0.97094,
  0.97248, 0.9245, 0.83319, 0.75859, 1.1293, 1.2566, 1.2566, 1.2566, 1.12308,
  1.12308, 1.12308, 1.12308, 1.15601, 1.02933, 1.02933, 0.90747, 1.02933, 1,
  0.68787, 0.85284, 0.88832, 0.96334, 1, 0.77832, 0.77832, 0.77832, 0.77832,
  0.77832, 1, 1, 0.89552, 0.91133, 0.774, 1, 1, 0.88178, 0.84438, 0.91133,
  1.39543, 1, 1, 0.91133, 0.7589, 1, 1.20562, 1.03525, 1.23023, 0.97655, 1,
  1.0297, 0.9245, 0.91133, 0.84426, 0.80972, 1.35191, 1, 0.95161, 0.70508, 1, 1,
  1, 1, 0.91133, 0.9245, 0.9245, 0.9245, 0.9245, 0.9245, 0.9245, 0.9245, 0.9245,
  0.89552, 0.90186, 1, 1, 1.0088, 1.0044, 1, 0.739, 0.79776, 1.20562, 1,
  0.96309, 0.73206, 0.89693, 0.85865, 0.90933, 1, 1, 1.4956, 0.97858, 1, 1,
  2.01462, 1, 1, 1, 1.05859, 1.05859, 0.9245, 1, 0.9849, 0.9849, 0.9849, 0.9849,
  0.9245, 0.9245, 0.8916, 0.98986, 0.98986, 0.98986, 0.98986, 0.98986, 0.86621,
  0.84153, 0.89453, 0.89453, 0.89453, 0.89453, 0.91133, 0.89453, 0.79004,
  0.79004, 0.79004, 0.79004, 0.91133, 0.75026,
];

export { CalibriItalicFactors };
