/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Regular.ttf to have the same
// metrics as NimbusSans-Regular.otf.
const HelveticaRegularFactors = [
  0.76116, 1, 1.00001, 1, 1, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 0.99928, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1.00001,
  0.99977, 0.99977, 0.99977, 0.91621, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 0.9998, 1.00001, 1.00001,
  0.99977, 0.99977, 1.00013, 0.99977, 0.99973, 1.00026, 1.00022, 1.0004,
  1.00022, 1.00022, 1.00022, 1.00022, 0.99977, 0.99999, 0.99861, 0.99861, 1,
  0.99977, 0.99977, 1.0006, 1.00019, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.00055, 1.0006, 1, 1, 1.00001,
  1.00001, 1.00001, 0.99973, 0.99973, 1.00005, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99999, 0.99999, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 1.00022, 1, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022,
  1.00022, 1, 0.99941, 1.00022, 0.99947, 1.00022, 1.00022, 1.00022, 1.00001,
  1.00019, 0.99977, 0.99946, 1.00022, 0.99977, 0.99977, 0.99977, 0.99977,
  1.00001, 1.00001, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.6, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 0.99973, 1.00026,
  1.00026, 1.00026, 1.00026, 1.00026, 1.00022, 1.00001, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1.00001,
  1.00001, 0.99945, 0.99977, 0.99977, 1.00001, 1.00016, 1.00016, 1.00016,
  1.00016, 1.00016, 1.00001, 1.00015, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99998, 0.99973, 0.99982, 0.99982, 1, 1.00001, 0.99962,
  1.00001, 1.00092, 0.99964, 1.00001, 0.99928, 0.99963, 0.99999, 1.00035,
  1.00035, 1.00082, 0.99962, 0.99999, 0.99977, 1.00022, 1.00035, 1.00001,
  0.99977, 1.00026, 0.9996, 0.99967, 1.00001, 1.00034, 1.00074, 1.00054,
  1.00053, 1.00063, 0.99971, 0.99962, 1.00035, 0.99975, 0.99977, 1.00047,
  1.00029, 1.00092, 1.00035, 1.00001, 1.0006, 1.0006, 1, 0.99988, 0.99975, 1,
  1.00082, 0.9996, 0.99973, 1.00043, 0.99953, 1.0007, 0.99915, 0.99973, 0.99973,
  1.00008, 0.99892, 1.00073, 1.00073, 1.00114, 0.99915, 1.00073, 0.99955,
  0.99973, 1.00092, 0.99973, 1, 0.99998, 1, 1.0003, 1, 1.00043, 1.00001,
  0.99969, 1.0003, 1, 1.00035, 1.00001, 0.9995, 1, 1.00092, 0.99968, 0.99973,
  1.0007, 0.9995, 1, 0.99924, 1.0006, 0.99924, 0.99972, 1.00062, 0.99973,
  1.00114, 1, 1.00035, 1, 0.99955, 1, 0.99971, 0.99925, 1.00023, 0.99973,
  0.99978, 0.99978, 0.99973, 1.00001, 1, 0.99973, 1.00031, 0.99973, 0.99973, 1,
  1, 1, 1, 1, 1, 1, 0.99949, 1.00003, 0.99959, 0.99987, 0.99973, 0.99973,
  1.0006, 1.0009, 0.99966, 1.41144, 1.00005, 1.00005, 1.0006, 1.0006, 0.99998,
  1.0009, 0.99972, 1, 1, 0.99998, 1, 1, 1, 1, 0.99998, 0.99973, 1.00019,
  0.99999, 0.99998, 0.99962, 1.0006, 1.0006, 1.00025, 0.99973, 0.99973, 0.99973,
  0.99973, 1.04596, 0.99973, 1.00024, 1.00065, 0.9995, 0.99998, 0.99998,
  1.06409, 1.36625, 1.41144, 0.99973, 0.99998, 1.0006, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 1, 0.99973, 1,
  0.99973, 0.99973, 0.99973, 1.00045, 1.00045, 1.00003, 0.99915, 0.99984,
  0.99973, 0.99973, 0.99973, 1.0006, 1, 0.99998, 1.0006, 1, 0.99999, 1, 0.99973,
  1.00002, 0.99973, 0.99973, 0.99973, 0.99973, 1, 0.99973, 0.99973, 0.99973,
  0.99973, 1.00026, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.99998, 1.00003, 1.00031,
  0.99973, 0.99973, 0.99998, 0.99998, 0.99973, 0.99973, 0.99973, 1.00042,
  0.99999, 0.99998, 0.99924, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.00034,
  1.0006, 1.00015, 1.00027, 0.99999, 0.99999, 1.00035, 0.99999, 0.99999,
  0.99977, 0.99924, 0.99924, 0.99924, 0.99924, 0.99924, 1.0006, 0.99924,
  0.99924, 1, 1, 1, 1, 0.99924, 0.99924, 1, 1.02572, 0.99924, 1.00005, 1.00003,
  1.00031, 1.41144, 0.99973, 1.00003, 0.99924, 0.95317, 0.99924, 1.40579,
  0.99999, 0.60299, 1, 1, 1.00003, 1.00267, 0.96499, 1.00003, 1, 1, 0.99973,
  0.99973, 0.99999, 0.99973, 0.99973, 0.99973, 1, 1.00031, 0.99973, 1, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 1.00016, 0.99998, 0.99973,
  0.99973, 0.99973, 1.00031, 1.00031, 0.99973, 0.99973, 0.99973, 1.00002,
  1.00002, 1.00002, 1.40483, 0.99968, 0.99936, 1, 1.00026, 1.00026, 0.99973,
  0.99973, 0.9998, 0.99998, 0.99998, 0.96329, 0.99982, 1.0006, 1, 1.00023,
  0.99933, 1.00008, 1.00003, 1.06409, 1.20985, 1.00015, 0.99973, 0.99973,
  1.00026, 1.00006, 0.99998, 0.99998, 0.99998, 0.99924, 0.99924, 0.99924,
  0.99924, 1.00043, 0.99998, 0.99998, 0.8254, 0.99998, 0.99998, 1.00025,
  1.00003, 1.00027, 0.99998, 1.41144, 1, 1, 1, 1, 1, 1, 0.99999, 0.99973,
  0.99973, 1.00002, 1.40579, 0.9997, 1, 0.99973, 1.0006, 1, 0.99953, 0.99973,
  1.39713, 1.00054, 1.0006, 0.99995, 1.0006, 0.84533, 1.0006, 0.99973, 0.99973,
  0.99973, 1.00002, 1.00002, 0.99998, 0.99998, 1, 1.00026, 1.00026, 1.00026,
  1.00026, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99955, 1.0006, 0.99998, 1, 1, 1, 1, 1.00026,
  1.0006, 0.99998, 1, 1.00001, 0.99561, 0.99973, 1.00073, 1, 1, 0.99998, 1, 1,
  1, 1, 1, 0.99991, 1, 1.66475, 1.0006, 1, 1, 1, 1, 1, 0.99973, 1.41144,
  1.00023, 1.00023, 1.00023, 1.00023, 0.99973, 0.99973, 1, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 1, 1.00055, 1, 1, 1, 1, 0.99973, 1, 1, 1, 1, 1,
  0.99973, 1.00019,
];

export { HelveticaRegularFactors };
