/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Regular.ttf to have the same
// metrics as calibri.ttf.
const CalibriRegularFactors = [
  1.3877, 1, 0.8675, 0.76318, 0.76318, 0.8675, 0.8675, 0.8675, 0.8675, 0.8675,
  0.8675, 0.86686, 0.8675, 0.8675, 0.8675, 0.8675, 0.8675, 0.81552, 0.81552,
  0.73834, 0.73834, 0.73834, 0.73834, 0.73834, 0.73834, 0.77818, 0.85193,
  0.85193, 0.86477, 0.84503, 0.73206, 0.73206, 0.73206, 0.73206, 0.73206,
  0.73206, 0.73206, 0.73206, 0.73206, 0.86901, 0.73206, 0.73206, 0.62267,
  0.86275, 0.74359, 0.86477, 0.91133, 0.7522, 0.81105, 0.75443, 0.81105,
  0.81105, 1, 0.81105, 0.86275, 1, 0.99862, 0.99862, 1, 0.90872, 0.86275,
  0.90685, 0.77741, 0.90685, 0.90685, 0.90685, 0.90685, 0.90685, 0.90685,
  0.90685, 0.90685, 0.90685, 0.90685, 0.65649, 0.90685, 0.6377, 0.6377, 0.77892,
  0.77892, 1, 0.75593, 0.75593, 0.85746, 0.76032, 1, 0.98156, 0.77261, 1.02638,
  1.02638, 0.89385, 0.89385, 0.89385, 1, 0.89385, 0.89385, 0.85122, 0.8667,
  0.85122, 0.85122, 0.85122, 0.85122, 0.85122, 0.85122, 0.85122, 0.88831,
  0.88254, 0.85122, 0.85498, 0.85311, 0.85311, 0.85122, 0.77452, 0.95165,
  0.86275, 0.89772, 0.86503, 0.75186, 0.75186, 0.75186, 1, 0.77452, 0.68887,
  0.70572, 0.70572, 0.70572, 0.70572, 1, 1, 1, 1, 1, 0.70572, 0.8, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.68887, 0.68887, 0.68887, 0.68887, 1, 0.74171, 0.79776, 0.79776, 0.79776,
  0.79776, 1, 0.85122, 0.77452, 0.88844, 0.88844, 0.88844, 0.88844, 0.88844,
  0.88844, 0.88844, 0.88844, 0.88844, 0.7306, 0.7306, 0.56963, 0.88844, 0.88844,
  0.85066, 0.94258, 0.94258, 0.94258, 0.94258, 0.94258, 0.77818, 0.75657,
  0.7306, 0.7306, 0.7306, 0.7306, 0.7306, 0.76659, 0.76659, 0.76659, 0.76659,
  0.76659, 0.86128, 0.86128, 0.86128, 0.86128, 0.87683, 0.86128, 0.8693, 0.8693,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.86128,
  0.98142, 0.98142, 0.86128, 1.02269, 0.90861, 0.86128, 0.90747, 0.86128,
  0.86128, 1.28809, 1.81055, 0.90527, 0.90527, 1.81055, 1.55469, 0.94434,
  1.06139, 0.85284, 1.2798, 0.88071, 0.86128, 0.94469, 1.39016, 1.77256, 0.9236,
  1, 0.94152, 0.94152, 1.10369, 1.10369, 1.1437, 1.91729, 1.42259, 0.8457,
  0.8457, 1.18622, 0.8457, 0.8457, 0.8457, 0.8457, 0.92229, 0.89552, 0.81209,
  0.91027, 1.18622, 1, 0.96309, 0.89807, 1.13254, 0.89552, 0.94469, 0.89552,
  0.89552, 0.92454, 0.9921, 0.84737, 0.94035, 1, 1.17889, 1.48387, 0.90747, 1,
  1, 0.91133, 0.67742, 0.82601, 0.89464, 0.89464, 0.89464, 0.89464, 0.89464,
  0.89464, 0.89464, 0.89464, 0.91133, 0.69043, 0.89464, 0.90527, 0.89552,
  0.94469, 0.89464, 1.02191, 1.02191, 0.85284, 0.85356, 1.23108, 0.96576,
  0.96576, 0.94469, 1.17223, 1.11523, 0.97801, 1.09842, 1, 1, 1, 0.91133,
  0.84426, 0.54873, 0.91133, 0.82616, 0.84636, 0.89258, 0.84636, 0.84636, 1,
  0.84636, 0.86331, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.8739, 0.85284, 0.90747,
  0.92098, 0.92098, 1.0176, 1.0176, 0.94469, 0.95786, 0.94469, 1, 1.44947,
  1.40909, 1.03297, 0.82601, 0.82601, 0.82601, 0.82601, 0.82601, 1.05611,
  0.82601, 1.19658, 1.33512, 0.8941, 0.8941, 0.97622, 1, 1, 1, 1.03297, 1.23516,
  1.23516, 1.23516, 1.23516, 0.82601, 1.07692, 1.07692, 0.90918, 0.90918, 1,
  0.90918, 1.03297, 1.03297, 0.92578, 0.90452, 1, 1.11842, 0.85284, 0.90747, 1,
  0.91133, 0.85284, 1.09231, 1.0336, 1.11429, 1, 0.95897, 0.71353, 1, 1,
  0.85284, 1, 0.95424, 0.85284, 1, 1, 0.94469, 0.94469, 0.95877, 0.94469, 1,
  0.91133, 1, 0.90747, 0.94469, 0.89746, 0.89552, 0.9482, 0.9482, 0.9482,
  0.9482, 0.9482, 0.90016, 0.93695, 0.9482, 0.9482, 0.9482, 0.89181, 0.89181,
  0.9482, 0.9482, 0.91133, 0.79801, 0.80504, 0.76288, 0.99862, 1.08707, 1.15642,
  0.69825, 0.86651, 0.86651, 0.9482, 0.94469, 1.09091, 0.91056, 0.91056,
  1.07806, 0.80395, 0.90861, 1.03809, 0.84286, 1.00452, 0.80113, 0.85284,
  0.90747, 0.97094, 0.99247, 0.94469, 0.83319, 0.75859, 1.1293, 1.2566, 1.2566,
  1.2566, 1.12308, 1.12308, 1.12308, 1.12308, 1.15601, 1.04692, 1.04692,
  0.90747, 1.04692, 1, 0.68787, 0.85284, 0.89442, 0.96334, 1, 0.78223, 0.78223,
  0.78223, 0.78223, 0.78223, 1, 1, 0.89552, 0.91133, 0.774, 1, 0.86155, 0.85208,
  0.91133, 1.39016, 1, 1, 0.91133, 0.7589, 1, 1.20562, 0.98022, 1.23023,
  0.92188, 1, 0.9561, 0.94469, 0.91133, 0.84426, 0.80972, 1.35191, 0.95161,
  0.70508, 1, 1, 1, 1, 0.91133, 0.94469, 0.94469, 0.94469, 0.94469, 0.94469,
  0.94469, 0.94469, 0.94469, 0.89552, 0.90186, 1, 1, 1.0088, 1.0044, 1, 0.739,
  0.79776, 1.20562, 1, 0.96309, 0.73206, 0.88844, 0.89464, 0.96766, 1, 1,
  1.4956, 1.07185, 0.99413, 0.96334, 1.08065, 0.99331, 1, 1, 2.01462, 1, 1, 1,
  1, 1.05859, 1.05859, 0.94469, 1, 0.99018, 0.99018, 0.99018, 0.99018, 0.94469,
  0.94469, 0.90332, 0.98986, 0.98986, 0.98986, 0.98986, 0.98986, 0.86621,
  0.83969, 0.90527, 0.90527, 0.90527, 0.90527, 0.91133, 0.90527, 0.79004,
  0.79004, 0.79004, 0.79004, 0.91133, 0.78848,
];

export { CalibriRegularFactors };
