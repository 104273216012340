/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Italic.ttf to have the same
// metrics as NimbusSans-Italic.otf.
const HelveticaItalicFactors = [
  0.76116, 1, 1.00001, 1, 1, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1.00001,
  0.99977, 0.99977, 0.99977, 0.91221, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 0.9998, 1.00001, 1.00001,
  1.00054, 0.99977, 0.99977, 0.99977, 0.99973, 1.00026, 1.00022, 0.99945,
  1.00022, 1.00022, 1.00022, 1.00022, 0.99977, 0.99999, 0.99861, 0.99861, 1,
  0.99977, 0.99977, 1.0006, 0.99946, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.00084, 1.0006, 1, 1, 1.00001,
  1.00001, 1.00001, 0.99973, 0.99973, 1.00001, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99999, 0.99999, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 1.00022, 1, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022,
  1.00022, 1, 1.00013, 1.00022, 1.00007, 1.00022, 1.00022, 1.00022, 1.00001,
  1.0001, 1.00054, 1.00052, 1.00022, 0.99977, 0.99977, 0.99977, 0.99977,
  1.00001, 1.00001, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.6, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 1.00065, 1.00026,
  1.00026, 1.00026, 1.00026, 1.00026, 1.00022, 1.00001, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1.00001,
  1.00001, 0.99973, 0.99977, 0.99977, 1.00001, 1.00016, 1.00016, 1.00016,
  1.00016, 1.00016, 1.00001, 0.99933, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99998, 1, 0.99973, 0.99982, 0.99982, 1, 1.00001, 1.00019,
  1.00001, 1.0001, 0.99986, 1.00001, 1.00001, 1.00001, 1.00038, 0.99954,
  0.99954, 0.9994, 1.00066, 0.99999, 0.99977, 1.00022, 1.00054, 1.00001,
  0.99977, 1.00026, 0.99975, 1.0001, 1.00001, 0.99993, 0.9995, 0.99955, 1.00016,
  0.99978, 0.99974, 1.00019, 1.00022, 0.99955, 1.00053, 0.99962, 1.00027,
  1.0001, 1.00068, 1.00001, 1.0006, 1.0006, 1, 1.00008, 0.99957, 0.99972,
  0.9994, 0.99975, 0.99973, 1.00089, 1.00005, 0.99967, 1.00048, 0.99973,
  0.99973, 1.00002, 1.00034, 0.99973, 0.99973, 0.99964, 1.00006, 1.00066,
  0.99947, 0.99973, 0.98894, 0.99973, 1, 0.44898, 1, 0.99946, 1, 1.00039,
  1.00082, 0.99991, 0.99991, 0.99985, 1.00022, 1.00023, 1.00061, 1.00006,
  0.99966, 0.99895, 0.99973, 1.00019, 1.0008, 1, 0.99924, 0.99924, 0.99924,
  0.99983, 1.00044, 0.99973, 0.99964, 1, 1.00051, 1, 0.99973, 1, 1.00423,
  0.99925, 0.99999, 0.99973, 0.99945, 0.99945, 0.99973, 1.00001, 1, 0.99973,
  1.00031, 0.99973, 0.99973, 1, 1, 1.00049, 1.00245, 1, 1, 1, 0.99949, 1.00003,
  0.99959, 0.99987, 0.99973, 0.99973, 1.0006, 1.0009, 0.99949, 1.41144, 1.00005,
  1.00005, 1.0006, 1.0006, 0.99998, 1, 1.0009, 0.99972, 1, 1, 0.99998, 1, 1, 1,
  1, 1, 1, 0.99998, 0.99973, 1.00019, 0.99999, 0.99998, 1, 0.99962, 1.0006,
  1.0006, 1, 1, 1.00025, 0.99973, 1, 0.99973, 0.99973, 0.99973, 1.0288, 0.99973,
  1.00024, 1.0006, 0.9995, 0.99998, 1, 0.99998, 1.06409, 1.36625, 1.41144,
  0.99973, 0.99998, 1.0006, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99973, 0.99998, 1, 0.99973, 1, 0.99973, 0.99973,
  0.99973, 0.99897, 0.99897, 1.00003, 1.00003, 0.99984, 0.99968, 0.99968,
  0.99973, 1.0006, 1, 0.99998, 1.0006, 1, 0.99999, 1, 0.99973, 1.00002, 0.99998,
  0.99973, 0.99973, 0.99998, 0.99973, 0.99973, 1, 0.99973, 0.99973, 0.99973,
  0.99973, 1.00026, 0.99998, 1, 1.00003, 1.00031, 0.99973, 0.99973, 0.99998,
  0.99998, 0.99973, 0.99973, 0.99973, 1.00042, 0.99999, 0.99998, 1, 0.99924,
  1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.00034, 1.0006, 1.00015, 1.00027,
  0.99999, 0.99999, 1.00035, 0.99999, 0.99999, 0.99977, 0.99924, 0.99924,
  0.99924, 0.99924, 0.99924, 1.0006, 0.99924, 0.99924, 1, 1, 1, 1, 0.99924,
  0.99924, 0.99962, 1.06311, 0.99924, 1.00024, 1.00003, 1.00031, 1.41144,
  0.99973, 1.00003, 0.99924, 0.95317, 0.99924, 1.40579, 0.99999, 0.60299, 1, 1,
  1.00003, 1.00267, 1.01487, 1.00003, 1, 1, 0.99973, 0.99973, 1.00041, 0.99973,
  0.99973, 0.99973, 1, 1.00031, 0.99973, 1, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99973, 1.00016, 0.99998, 0.99973, 0.99973, 0.99973, 1.00025,
  1.00025, 0.99973, 0.99973, 0.99973, 1.00002, 1.00002, 1.00002, 1.40483,
  0.99968, 0.99936, 1, 1.00026, 1.00026, 0.99973, 0.99973, 0.9998, 0.99998,
  0.99998, 0.96329, 0.99982, 1.0006, 1, 1.00023, 0.99947, 1.00012, 1.00003,
  1.06409, 1.20985, 1.00063, 0.99973, 0.99973, 1.00026, 1.00006, 0.99998,
  0.99998, 0.99998, 0.99924, 0.99924, 0.99924, 0.99924, 1.00043, 0.99998,
  0.99998, 0.8254, 0.99998, 0.99998, 1.00025, 1.00003, 1.00043, 0.99998,
  1.41144, 1, 1, 1, 1, 1, 1, 0.99999, 0.99973, 0.99973, 1.00002, 0.99998,
  1.40579, 0.99995, 1, 0.99973, 1.0006, 1, 0.99953, 0.99973, 1.39713, 1.00054,
  1.0006, 0.99994, 1.0006, 0.89547, 1.0006, 0.99911, 0.99973, 0.99973, 1.00002,
  1.00002, 0.99998, 1, 0.99998, 1, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99955, 1.0006, 0.99998, 1, 1, 1, 1, 1.00026, 1.0006, 0.99998, 1,
  1.00001, 0.99954, 0.99973, 0.98332, 1, 1, 0.99998, 1, 0.99991, 1, 1.66475,
  1.0006, 1, 1, 1, 1, 0.99973, 1.41144, 1.00036, 1.00036, 1.00036, 1.00036,
  0.99973, 0.99973, 1, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1, 1.0005,
  1, 1, 1, 1, 0.99973, 1, 1, 1, 1, 1, 0.99973, 0.99918,
];

export { HelveticaItalicFactors };
