/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Bold.ttf to have the same
// metrics as segoeuib.ttf.
const SegoeuiBoldFactors = [
  1.76738, 1, 0.97363, 0.93506, 0.93506, 0.97363, 0.97363, 0.97363, 0.97363,
  0.97363, 0.97363, 1.01149, 0.97363, 0.97363, 0.97363, 0.97363, 0.97363,
  0.88776, 0.88776, 0.8641, 0.8641, 0.8641, 0.8641, 0.8641, 0.8641, 0.98243,
  1.02096, 1.02096, 1.02096, 0.97826, 0.79795, 0.79795, 0.79795, 0.79795,
  0.79795, 0.79795, 0.79795, 0.79795, 0.79795, 1.09251, 0.79795, 0.79795,
  0.7676, 1.06085, 0.98167, 1.02096, 1.03424, 0.85132, 0.914, 0.85134, 0.914,
  0.914, 1, 0.914, 1.06085, 1, 0.99862, 0.99862, 1, 1.06085, 1.06085, 1.1406,
  0.97138, 1.1406, 1.1406, 1.1406, 1.1406, 1.1406, 1.1406, 1.1406, 1.1406,
  1.1406, 1.1406, 1.01134, 1.1406, 0.8007, 0.8007, 0.89858, 0.89858, 1, 0.83693,
  0.83693, 1.0388, 0.83693, 1, 0.83693, 0.83693, 1.14889, 1.14889, 1.09398,
  1.09398, 1.09398, 1, 1.09398, 1.09398, 0.97489, 0.93994, 0.97426, 0.97489,
  0.97426, 0.97426, 0.97426, 0.97489, 0.97489, 0.95493, 1.03089, 0.97489,
  1.02546, 0.97489, 0.97489, 0.97426, 0.92094, 0.99346, 1.0595, 1.02112,
  0.97489, 0.90399, 0.90399, 0.90399, 1, 0.92094, 0.84041, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 0.84041, 0.84041, 0.84041, 0.84041, 1, 0.94793, 0.95923,
  0.95923, 0.95923, 0.95923, 1, 0.97489, 0.92094, 1.00135, 1.00135, 1.00135,
  1.00135, 1.00135, 1.00135, 1.00135, 1.00135, 1.00135, 0.90996, 0.90996,
  0.84097, 1.00135, 1.00135, 1, 1.06467, 1.06467, 1.06467, 1.06467, 1.06467,
  0.98243, 0.86039, 0.90996, 0.90996, 0.90996, 0.90996, 0.90996, 0.99361,
  0.99361, 0.99361, 0.99361, 0.99361, 0.96752, 0.96752, 0.96752, 0.96752,
  0.91056, 1, 0.96752, 0.93136, 0.93136, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 0.96752, 1.03574, 1.03574, 0.96752, 1.17647,
  0.81525, 0.96752, 1.29004, 0.96752, 0.96752, 0.86182, 0.94434, 0.86279,
  0.86279, 0.94434, 1, 1, 1.2107, 1.2107, 1.16939, 0.97847, 0.96752, 1.01519,
  1.56942, 1.16579, 1.0192, 1, 0.94856, 0.94856, 1.1085, 1.1085, 1.03959, 1,
  1.16579, 1.20642, 0.86304, 0.86304, 1.15103, 1, 1, 0.86304, 0.86304, 0.86304,
  0.86304, 0.64663, 1.03424, 0.96268, 1.42603, 1.15103, 1, 1.04315, 0.81378,
  0.9754, 1, 1, 1.18622, 1, 1, 1.01359, 0.73047, 0.73047, 1.03057, 1.03517,
  0.94994, 1.00886, 1.34163, 1.3871, 1, 0.87815, 1.28826, 1, 1, 1.03424,
  0.75953, 1.02285, 0.97278, 0.97278, 0.97278, 0.97278, 0.97278, 0.97278,
  0.97278, 0.97278, 1.03424, 1, 0.91211, 0.97278, 1, 0.89903, 0.99041, 0.97278,
  0.97531, 1.02597, 1.2107, 1.21172, 1, 1.0008, 1.0008, 0.97122, 0.9824,
  0.96039, 0.9824, 1.15103, 1, 1.42603, 1, 1.03424, 1.15749, 1, 1.03424,
  1.03424, 1, 0.93503, 1.01359, 1.01932, 1.01359, 1.01359, 1, 1.01359, 1.02798,
  0.94282, 1, 1.2107, 1.29004, 1.04478, 1.04478, 1.03372, 1.03372, 0.98561,
  1.00879, 0.98561, 1.15296, 1, 1.21994, 1, 1.02285, 1.02285, 1.02285, 1.02285,
  1.02285, 1.02285, 1.08692, 1.02285, 1.13973, 2.10339, 1, 1, 0.9716, 1.42603,
  1.42603, 1, 1.02285, 1.18102, 1.18102, 1.18102, 1.18102, 1.02285, 1.02285,
  1.02285, 1.00527, 1.02277, 1, 1.00527, 1.02285, 1.02285, 0.98683, 0.9455, 1,
  0.90418, 1.2107, 1.29004, 1, 1.03424, 1.2107, 1.07733, 1.18874, 1.13005, 1,
  1.0302, 0.75155, 1, 1, 1.2107, 1.222, 1.0016, 1.2107, 1, 1, 0.99041, 0.99041,
  0.96692, 0.99041, 1, 1.03424, 1, 1.29004, 0.99041, 0.99561, 1.06497, 1.0008,
  1, 1.0008, 1, 1, 0.97931, 0.79912, 1, 1.0008, 1.0008, 0.98439, 0.98439,
  1.0008, 1.0008, 1.03424, 1.15749, 1.15749, 1.14169, 0.99862, 1.10818, 1.24866,
  0.69825, 1.0008, 1.0008, 1, 1.01519, 0.91572, 1.1085, 1.1085, 1.16897,
  0.97529, 0.9754, 1.25635, 1.19687, 1.04983, 0.90434, 1.2107, 1.28826, 0.96085,
  1.0499, 1.01359, 0.71703, 0.71703, 1.04016, 0.98633, 0.98633, 0.98633,
  1.04394, 1.04394, 1.04394, 1.04394, 1.23203, 1.02258, 1.02258, 1.18416,
  1.02258, 1, 1.18622, 1, 0.99921, 0.81378, 1, 0.79104, 0.79104, 0.79104,
  0.79104, 0.79104, 1, 1.02956, 0.8727, 1.03424, 1.10948, 1, 1, 0.8965, 0.93803,
  1.03424, 1.59578, 1, 1.2886, 1.03424, 0.90137, 1, 1.16862, 1.23085, 1.16862,
  1.07034, 1, 1.13189, 1.01519, 1.03424, 1.15749, 1.17389, 1.15103, 1, 0.95161,
  0.771, 0.87025, 0.87025, 0.87025, 0.87025, 1.03424, 0.99041, 0.99041, 0.99041,
  0.99041, 0.99041, 0.99041, 0.99041, 0.99041, 0.74627, 0.75155, 0.99297,
  1.21408, 1.21408, 1.21408, 1, 1.18328, 0.95923, 1.16862, 1.01173, 0.81378,
  0.79795, 1.09466, 0.97278, 1.02065, 1, 1.21408, 1.24633, 1.12454, 1, 1, 1,
  0.9754, 1, 1, 1.09193, 1.09193, 0.99041, 1, 1.06628, 1.06628, 1.06628,
  1.06628, 0.99041, 0.99041, 0.97454, 1.02511, 1.02511, 1.02511, 1.02511,
  1.02511, 0.99298, 1.07237, 0.96752, 0.96752, 0.96752, 0.96752, 1.03424,
  0.96752, 0.95801, 0.95801, 0.95801, 0.95801, 1.03424, 1.0106,
];

export { SegoeuiBoldFactors };
