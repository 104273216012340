/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Bold.ttf to have the same
// metrics as NimbusSans-Bold.otf.
const HelveticaBoldFactors = [
  0.76116, 1, 0.99977, 1, 1, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1.00001,
  0.99977, 0.99977, 0.99977, 0.85148, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 0.9998, 1.00001, 1.00001,
  0.99997, 0.99977, 1.00026, 0.99977, 0.99973, 1.00026, 1.00022, 0.99988,
  1.00022, 1.00022, 1.00022, 1.00022, 0.99977, 0.99999, 0.99861, 0.99861, 1,
  0.99977, 0.99977, 1.0006, 1.00042, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.00078, 1.0006, 0.99973, 0.99973,
  0.99977, 0.99977, 0.99977, 1.00026, 1.00026, 1.00001, 1.00026, 1.00026,
  1.00026, 1.00026, 0.99999, 0.99999, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 0.99977, 1.00022, 1, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022,
  1.00022, 1.00022, 1, 1.00013, 1.00022, 1.00036, 1.00022, 1.00022, 1.00022,
  1.00001, 1.00024, 0.99977, 0.9999, 1.00022, 0.99977, 0.99977, 0.99977,
  0.99977, 1.00001, 1.00001, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.6, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 0.99984,
  1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 1.00022, 1.00001, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  1.00001, 1.00001, 0.99973, 0.99977, 0.99977, 1.00001, 1.00016, 1.00016,
  1.00016, 1.00016, 1.00016, 1.00001, 1.00069, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00001, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99998, 1, 0.99973, 0.99982, 0.99982, 1, 0.99977,
  1.00035, 0.99977, 1.00019, 0.99944, 1.00001, 1.00008, 1.00021, 0.99926,
  1.00035, 1.00035, 0.99942, 1.00048, 0.99999, 0.99977, 1.00022, 1.00035,
  1.00001, 0.99977, 1.00026, 0.99989, 1.00057, 1.00001, 0.99936, 1.00052,
  1.00012, 0.99996, 1.00043, 1, 1.00035, 0.9994, 0.99976, 1.00035, 1.00038,
  0.99971, 1.00019, 0.9994, 1.00001, 1.0006, 1.00044, 0.99973, 1.00023, 1.00047,
  1, 0.99942, 0.99989, 0.99973, 1.00052, 1.00041, 1.00119, 1.00037, 0.99973,
  0.99973, 1.00002, 0.99986, 1.00041, 1.00041, 0.99902, 0.9996, 1.00034,
  0.99999, 1.00026, 0.99999, 1.00026, 0.99973, 1.00052, 0.99973, 1, 0.99973,
  1.00041, 1.00075, 0.9994, 1.0003, 0.99999, 1, 1.00041, 0.99955, 1, 0.99915,
  1.0005, 1.00026, 1.00119, 0.99955, 0.99973, 1.0006, 0.99911, 1.0006, 1.00026,
  0.99972, 1.00026, 0.99902, 0.99973, 1.00035, 1, 0.99999, 1, 0.99971, 1.00047,
  1.00023, 0.99973, 1.00041, 1.00041, 0.99973, 0.99977, 1, 0.99973, 1.00031,
  0.99973, 0.99973, 1, 1, 1, 1, 1, 1, 1, 1.00003, 1.00003, 0.99959, 0.9999,
  0.99973, 1.00026, 1.0006, 1.00077, 0.99942, 1.41144, 0.99959, 0.99959,
  0.99998, 0.99998, 0.99998, 1, 1.00077, 0.99972, 0.99973, 0.99973, 0.99998, 1,
  1, 0.99973, 0.99973, 0.99973, 0.99973, 0.99998, 0.99973, 1.00055, 0.99999,
  0.99998, 1, 0.99962, 0.99998, 1.0006, 1, 1, 1.00025, 0.99973, 1, 1.00026,
  0.99973, 0.99973, 1.03374, 1.00026, 1.00024, 0.99927, 0.9995, 0.99998, 1,
  1.00034, 1.06409, 1.36625, 1.41144, 0.99973, 0.99998, 1.0006, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99998, 1, 0.99973, 1, 0.99973, 1.00026, 0.99973, 1.00082, 0.99962, 1.00003,
  0.99915, 0.99984, 1.00026, 1.00026, 1.00026, 0.99998, 0.99999, 0.99998,
  0.99998, 1, 0.99999, 1, 0.99973, 1.00002, 0.99998, 0.99973, 0.99973, 0.99998,
  0.99973, 1.00026, 0.99973, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026,
  0.99998, 1, 1.00003, 1.00031, 0.99973, 0.99973, 0.99998, 0.99998, 1.00026,
  1.00026, 1.00026, 1.00042, 0.99999, 0.99998, 1, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 0.99973, 1.0006, 1.00015, 1.00027, 0.99999, 0.99999,
  0.99561, 0.99999, 0.99999, 0.99977, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 0.99973, 1.00069, 0.99973, 0.99973, 1.0006, 1.0006,
  0.99973, 1.03828, 1.0006, 0.99999, 1.00003, 1.00031, 1.41144, 0.99973,
  1.00003, 1.0006, 0.99972, 1.0006, 1.40579, 0.99982, 0.60299, 1, 1, 1.00003,
  1.00106, 0.99867, 1.00003, 1, 1, 1.00026, 1.00026, 0.9993, 1.00026, 1.00026,
  0.99973, 1, 1.00031, 1.00026, 0.99973, 0.99973, 1.00026, 1.00026, 1.00026,
  1.00026, 1.00026, 1.00016, 0.99998, 1.00026, 1.00026, 1.00026, 1.00032,
  1.00032, 1.00026, 1.00026, 0.99973, 1.00002, 1.00002, 1.00002, 1.40483,
  0.99968, 0.99936, 1, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973, 0.99998,
  0.99998, 0.99972, 0.99982, 1.0006, 1, 1.00023, 0.99954, 1.00049, 1.00003,
  1.06409, 1.20985, 0.99945, 1.00026, 1.00026, 1.00026, 0.99974, 1, 1, 1,
  1.0006, 1.0006, 1.0006, 1.0006, 1.00087, 0.99959, 0.99959, 1.00031, 0.99959,
  0.99959, 1.00025, 1.00003, 1.00056, 0.99998, 1.41144, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99973, 0.99999, 0.99973, 0.99973, 1.00002,
  0.99998, 1.40579, 0.99988, 1, 0.99973, 1.0006, 1, 0.99953, 0.99973, 1.39713,
  1.00054, 0.99998, 0.99935, 0.99998, 0.8121, 0.99998, 1.00087, 1.00026,
  0.99973, 1.00002, 1.00002, 0.99998, 1, 0.99998, 1, 1.00026, 1.00026, 1.00026,
  1.00026, 0.99973, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026,
  1.00026, 1.00026, 0.99973, 0.99955, 1.0006, 0.99998, 1, 1, 1, 1, 1.00026,
  0.99998, 0.99998, 1, 1.00001, 0.99561, 0.99973, 1.00041, 1, 1, 0.99998, 1,
  0.99991, 1, 1.66475, 1.0006, 1, 1, 1, 1, 1.00026, 1.41144, 0.99995, 0.99995,
  0.99995, 0.99995, 1.00026, 1.00026, 0.99973, 1.00022, 1.00022, 1.00022,
  1.00022, 1.00022, 0.99973, 0.9993, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99973, 1, 1, 1, 1, 0.99973, 0.99902,
];

export { HelveticaBoldFactors };
