/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-BoldItalic.ttf to have the same
// metrics as segoeuiz.ttf.
const SegoeuiBoldItalicFactors = [
  1.76738, 1, 0.97363, 0.94385, 0.94385, 0.97363, 0.97363, 0.97363, 0.97363,
  0.97363, 0.97363, 1.00811, 0.97363, 0.97363, 0.97363, 0.97363, 0.97363,
  0.89723, 0.89723, 0.87897, 0.87897, 0.87897, 0.87897, 0.87897, 0.87897,
  0.9817, 1.0426, 1.0426, 1.0426, 1.09818, 0.79429, 0.79429, 0.79429, 0.79429,
  0.79429, 0.79429, 0.79429, 0.79429, 0.79429, 1.10466, 0.79429, 0.79429,
  0.77702, 1.05815, 0.99137, 1.0426, 1.036, 0.85292, 0.91149, 0.86869, 0.91149,
  0.91149, 1, 0.91149, 1.05815, 1, 0.99862, 0.99862, 1, 1.05815, 1.05815,
  1.1406, 0.97441, 1.1406, 1.1406, 1.1406, 1.1406, 1.1406, 1.1406, 1.1406,
  1.1406, 1.1406, 1.1406, 0.95253, 1.1406, 0.79631, 0.79631, 0.90128, 0.90128,
  1, 0.83853, 0.83853, 1.06662, 0.83853, 1, 0.83853, 0.83853, 1.04396, 1.04396,
  1.10615, 1.10615, 1.10615, 1, 1.10615, 1.10615, 0.97552, 0.91602, 0.97552,
  0.97552, 0.97552, 0.97552, 0.97552, 0.97552, 0.97552, 0.98999, 1.07205,
  0.97552, 1.0347, 0.97552, 0.97552, 0.97552, 0.94436, 1.00356, 1.04694,
  1.01945, 0.97552, 0.88641, 0.88641, 0.88641, 1, 0.94436, 0.80527, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.80527, 0.80527, 0.80527, 0.80527, 1, 0.98015,
  0.96083, 0.96083, 0.95923, 0.96083, 1, 0.97552, 0.94436, 1.00135, 1.00135,
  1.00135, 1.00135, 1.00135, 1.00135, 1.00135, 1.00135, 1.00135, 0.91142,
  0.91142, 0.86142, 1.00135, 1.00135, 1, 1.06777, 1.06777, 1.06777, 1.06777,
  1.06777, 0.9817, 0.84918, 0.91142, 0.91142, 0.91142, 0.91142, 0.91142,
  0.99361, 0.99361, 0.99361, 0.99361, 0.99361, 1.06585, 1.06585, 1.06585,
  1.06585, 1.31818, 1, 1.06585, 0.96705, 0.96705, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.06585, 1.04961, 1.04961, 1.06585,
  1.10953, 0.83284, 1.06585, 1.29004, 1.06585, 1.06585, 0.86224, 0.94434,
  0.86364, 0.86279, 0.94434, 1, 1, 1.21237, 1.21237, 1.16939, 0.97847, 1.06585,
  0.97042, 1.57293, 1.16579, 0.99607, 1, 0.95107, 0.95107, 1.11144, 1.11144,
  1.31818, 1, 1.16579, 1.20502, 0.83055, 0.83055, 1.31818, 1, 1, 0.83055,
  0.83055, 0.83055, 0.83055, 1.31818, 1.036, 0.93491, 1.42603, 1.31818, 1,
  1.04315, 0.81378, 0.9754, 1, 1, 1.18754, 1, 1, 0.97042, 0.72959, 0.72959,
  1.0033, 0.97042, 0.94994, 1.008, 1.34163, 1.31818, 1, 1.27126, 1.29004, 1, 1,
  1.036, 1.31818, 1.14236, 0.93503, 0.93503, 0.93503, 0.93503, 0.93503, 0.93503,
  0.93503, 0.93503, 1.036, 1, 0.91406, 0.93503, 1, 0.89903, 0.97922, 0.93503,
  0.9898, 0.9898, 1.21237, 1.21339, 1, 1.00639, 1.00639, 0.93125, 1.03959,
  0.96039, 1.03959, 1.1261, 1, 1.42603, 1, 1.036, 1.15574, 1, 1.036, 1.036, 1,
  0.93327, 0.97042, 1.02897, 0.97042, 0.97042, 1, 0.97042, 0.98721, 1.31818, 1,
  1.21237, 1.29004, 1.05356, 1.05356, 1.03372, 1.03372, 0.97922, 0.97922,
  0.97922, 1.15296, 1, 1.31818, 1, 1.14236, 1.14236, 1.14236, 1.14236, 1.14236,
  1.14236, 1.04302, 1.14236, 1.13904, 2.10339, 1, 1, 0.9716, 1.42603, 1.42603,
  1, 1.14236, 1.14236, 1.14236, 1.14236, 1.14236, 1.14236, 0.94552, 1.01582,
  1.01054, 1.00518, 1, 1.01054, 1.14236, 1.14236, 0.97981, 1.09125, 1, 0.90418,
  1.21237, 1.29004, 1, 1.03336, 1.21237, 1.23199, 1.18775, 1.19508, 1, 1.02471,
  0.79487, 1, 1, 1.21237, 1.222, 1.02186, 1.21237, 1, 1, 0.97922, 0.97922,
  1.01034, 0.97922, 1, 1.036, 1, 1.29004, 0.97922, 1, 1.02809, 0.94165, 0.94165,
  0.94165, 0.94165, 0.94165, 0.91981, 1.31818, 0.94165, 0.94165, 0.94165,
  1.00351, 1.00351, 0.94165, 0.94165, 1.036, 1.15574, 1.15574, 1.13934, 0.99862,
  1.26781, 1.24866, 0.69825, 0.94165, 0.94165, 0.94165, 0.97042, 0.91484,
  1.11144, 1.11144, 1.16798, 0.97639, 0.9754, 1.26514, 1.16541, 1.10687,
  0.99314, 1.21237, 1.29004, 0.96085, 1.04232, 0.97042, 0.73541, 0.73541,
  1.04016, 0.98633, 0.98633, 0.98633, 1.04745, 1.04394, 1.04745, 1.04745,
  1.23203, 1.0276, 1.0276, 1.18416, 1.0276, 1, 1.18622, 1, 0.98387, 1.31818, 1,
  0.78929, 0.78929, 0.78929, 0.78929, 0.78929, 1, 1.02956, 0.87357, 1.036,
  1.15574, 1, 1, 0.93377, 0.93028, 1.036, 1.59754, 1, 1.2886, 1.036, 0.90068, 1,
  1.1261, 1.35125, 1.16862, 1.05403, 1, 1.11121, 0.97042, 1.036, 1.15574,
  1.17389, 1.31818, 1, 0.95161, 0.771, 0.87025, 0.87025, 0.87025, 0.87025,
  1.036, 0.97922, 0.97922, 0.97922, 0.97922, 0.97922, 0.97922, 0.97922, 0.97922,
  0.74627, 0.75155, 0.98946, 1.21261, 1.24047, 1.24047, 1, 1.1349, 0.96083,
  1.1261, 1.31818, 0.81378, 0.79429, 1.09097, 0.93503, 0.96609, 1, 1.21261,
  1.24633, 1.09125, 1, 1, 1, 0.9754, 1, 1, 1.13269, 1.13269, 0.97922, 1,
  1.07514, 1.07514, 1.07514, 1.07514, 0.97922, 0.97922, 0.95874, 1.02197,
  1.02197, 1.02197, 1.02197, 1.02197, 0.98507, 1.08578, 0.96752, 0.96752,
  0.96752, 0.96752, 1.036, 0.96752, 0.97168, 0.97168, 0.97168, 0.97168, 1.036,
  0.95134,
];

export { SegoeuiBoldItalicFactors };
