/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Bold.ttf to have the same
// metrics as calibrib.ttf.
const CalibriBoldFactors = [
  1.3877, 1, 0.83908, 0.77539, 0.77539, 0.83908, 0.83908, 0.83908, 0.83908,
  0.83908, 0.83908, 0.83908, 0.83908, 0.83908, 0.83908, 0.83908, 0.83908,
  0.7762, 0.7762, 0.73293, 0.73293, 0.73293, 0.73293, 0.73293, 0.73293, 0.82577,
  0.87289, 0.87289, 0.88506, 0.80367, 0.73133, 0.73133, 0.73133, 0.73133,
  0.73133, 0.73133, 0.73133, 0.73133, 0.73133, 0.88656, 0.73133, 0.73133,
  0.57184, 0.87356, 0.6965, 0.88506, 0.91133, 0.7514, 0.81921, 0.68156, 0.81921,
  0.81921, 1, 0.81921, 0.87356, 1, 0.99862, 0.99862, 1, 0.91075, 0.87356,
  0.95958, 0.76229, 0.95958, 0.95958, 0.95958, 0.95958, 0.95958, 0.95958,
  0.95958, 0.95958, 0.95958, 0.95958, 0.56289, 0.95958, 0.59526, 0.59526,
  0.75727, 0.75727, 1, 0.69225, 0.69225, 0.89019, 0.70424, 1, 0.91926, 0.70823,
  1.04924, 1.04924, 0.9121, 0.9121, 0.9121, 1, 0.9121, 0.9121, 0.86943, 0.87402,
  0.86943, 0.86943, 0.86943, 0.86943, 0.86943, 0.86943, 0.86943, 0.84896,
  0.81235, 0.86943, 0.82001, 0.87508, 0.87508, 0.86943, 0.79795, 0.9762,
  0.87356, 0.99819, 0.88198, 0.77958, 0.77958, 0.77958, 1, 0.79795, 0.70864,
  0.70572, 0.70572, 0.70572, 0.70572, 1, 1, 1, 1, 1, 0.70572, 0.8, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.70864, 0.70864, 0.70864, 0.70864, 1, 0.78275, 0.81055, 0.81055, 0.81055,
  0.81055, 1, 0.86943, 0.79795, 0.90399, 0.90399, 0.90399, 0.90399, 0.90399,
  0.90399, 0.90399, 0.90399, 0.90399, 0.77892, 0.77892, 0.56029, 0.90399,
  0.90399, 0.88653, 0.96017, 0.96017, 0.96017, 0.96017, 0.96017, 0.82577,
  0.7648, 0.77892, 0.77892, 0.77892, 0.77892, 0.77892, 0.78257, 0.78257,
  0.78257, 0.78257, 0.78257, 0.88762, 0.88762, 0.88762, 0.88762, 0.90323, 1,
  0.88762, 0.8715, 0.8715, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 0.88762, 0.96108, 0.96108, 0.88762, 0.97566, 0.80352, 0.88762,
  0.90747, 0.88762, 0.88762, 1.31006, 1.81055, 0.90527, 0.90527, 1.81055,
  1.53711, 0.94434, 0.85284, 0.85284, 1.2798, 0.92138, 0.88762, 0.8785, 1.54657,
  1.69808, 0.8936, 1, 0.88331, 0.88331, 0.97507, 0.97507, 1.15543, 1, 1.7801,
  1.42259, 0.75241, 0.75241, 1.20528, 1, 1, 0.75241, 0.75241, 0.75241, 0.75241,
  0.91056, 0.89552, 0.78287, 0.91027, 1.20528, 1, 1, 0.82845, 0.92794, 1, 1,
  1.13254, 0.89552, 1, 0.8785, 0.89552, 0.89552, 0.83016, 0.93125, 0.85592,
  0.87842, 1, 1.24487, 1, 1.06303, 0.90747, 1, 1, 0.91133, 0.70674, 0.88401,
  0.90518, 0.90518, 0.90518, 0.90518, 0.90518, 0.90518, 0.90518, 0.90518,
  0.91133, 1, 0.71143, 0.90518, 0.90527, 0.89552, 0.8785, 0.90518, 0.96399,
  1.01407, 0.85284, 0.85356, 1.23108, 0.89049, 0.89049, 0.8785, 0.97801,
  0.97171, 0.97801, 0.95015, 1, 1, 1, 0.91133, 0.88525, 1, 0.56892, 0.91133, 1,
  0.83406, 0.77618, 0.84021, 0.77618, 0.77618, 1, 0.77618, 0.90807, 0.90176, 1,
  0.85284, 0.90747, 0.96839, 0.96839, 1.03372, 1.03372, 0.8785, 0.89608, 0.8785,
  1, 1.44947, 1.45894, 1, 0.88401, 0.88401, 0.88401, 0.88401, 0.88401, 0.88401,
  0.90167, 0.88401, 1.17534, 1.37077, 0.8941, 0.8941, 0.9716, 1, 1, 1, 0.88401,
  1.02988, 1.02988, 1.02988, 1.02988, 0.88401, 0.91916, 0.91916, 0.86304,
  0.86077, 1, 0.86304, 0.88401, 0.88401, 0.87445, 0.79468, 1, 0.88175, 0.85284,
  0.90747, 1, 0.91133, 0.85284, 0.9297, 1.08004, 0.94903, 1, 0.91488, 0.70645,
  1, 1, 0.85284, 1, 0.92099, 0.85284, 1, 1, 0.8785, 0.8785, 0.87802, 0.8785, 1,
  0.91133, 1, 0.90747, 0.8785, 0.84723, 0.89552, 0.8801, 0.8801, 0.8801, 0.8801,
  0.8801, 0.89291, 0.94721, 0.8801, 0.8801, 0.8801, 0.84971, 0.84971, 0.8801,
  0.8801, 0.91133, 0.83489, 0.82845, 0.78864, 0.99862, 1.12401, 1.19118,
  0.69825, 0.89049, 0.89049, 0.8801, 0.8785, 1.07463, 0.93548, 0.93548, 1.08696,
  0.81988, 0.96134, 1.06152, 0.84107, 0.97747, 0.75638, 0.85284, 0.90747,
  0.95018, 0.97926, 0.8785, 0.75859, 0.75859, 0.92482, 0.87012, 0.87012,
  0.87012, 0.92794, 0.92794, 0.92794, 0.92794, 0.98152, 0.91343, 0.91343,
  0.90747, 0.91343, 1, 0.68787, 0.85284, 0.85714, 0.98387, 1, 0.7173, 0.7173,
  0.7173, 0.7173, 0.7173, 1, 1, 0.89552, 0.91133, 0.81499, 1, 1, 0.79586,
  0.78216, 0.91133, 1.54657, 1, 1, 0.91133, 0.77192, 1, 1.04106, 0.87965,
  1.06452, 0.75841, 1, 1.00813, 0.8785, 0.91133, 0.88525, 0.84133, 1.33431, 1,
  0.95161, 0.72021, 1, 1, 1, 1, 0.91133, 0.8785, 0.8785, 0.8785, 0.8785, 0.8785,
  0.8785, 0.8785, 0.8785, 0.89552, 0.90363, 1, 1, 1.01466, 1.0088, 1, 0.75806,
  0.81055, 1.04106, 1, 0.82845, 0.73133, 0.90264, 0.90518, 0.90548, 1, 1,
  1.4956, 0.93835, 1, 1, 2.2807, 1, 1, 1, 0.90727, 0.90727, 0.8785, 1, 0.94211,
  0.94211, 0.94211, 0.94211, 0.8785, 0.8785, 0.85075, 0.95794, 0.95794, 0.95794,
  0.95794, 0.95794, 0.82616, 0.86513, 0.85162, 0.85162, 0.85162, 0.85162,
  0.91133, 0.85162, 0.79492, 0.79492, 0.79492, 0.79492, 0.91133, 0.79109,
];

export { CalibriBoldFactors };
