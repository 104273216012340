/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-Italic.ttf to have the same
// metrics as segoeuii.ttf.
const SegoeuiItalicFactors = [
  1.76738, 1, 0.94729, 0.85498, 0.85498, 0.94729, 0.94729, 0.94729, 0.94729,
  0.94729, 0.94729, 0.94729, 0.94729, 0.94729, 0.94729, 0.94729, 0.94729,
  0.85944, 0.85944, 0.88506, 0.88506, 0.88506, 0.88506, 0.88506, 0.88506,
  0.8858, 0.9858, 0.9858, 0.9858, 0.9607, 0.74817, 0.74817, 0.74817, 0.74817,
  0.74817, 0.74817, 0.74817, 0.74817, 0.74817, 1.03849, 0.74817, 0.74817,
  0.71022, 0.98039, 0.90883, 0.9858, 0.96927, 0.80016, 0.88449, 0.82791,
  0.88449, 0.88449, 1, 0.88449, 0.98039, 1, 0.99862, 0.99862, 1, 0.98039,
  0.98039, 0.95782, 0.84421, 0.95782, 0.95782, 0.95782, 0.95782, 0.95782,
  0.95782, 0.95782, 0.95782, 0.95782, 0.95782, 0.84171, 0.95782, 0.69238,
  0.69238, 0.89898, 0.89898, 1, 0.83231, 0.83231, 0.98316, 0.84723, 1, 0.84723,
  0.83231, 0.98183, 0.98183, 1.03989, 1.03989, 1.03989, 1, 1.03989, 1.03989,
  0.96924, 0.92383, 0.96924, 0.96924, 0.96924, 0.96924, 0.96924, 0.96924,
  0.96924, 1.01284, 1.05734, 0.96924, 0.99877, 0.96924, 0.96924, 0.96924,
  0.86237, 0.90082, 0.97642, 0.97296, 0.96924, 0.80595, 0.80595, 0.80595, 1,
  0.86237, 0.74524, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.74524, 0.74524,
  0.74524, 0.74524, 1, 0.86075, 0.86091, 0.86091, 0.85771, 0.86091, 1, 0.96924,
  0.86237, 0.95402, 0.95402, 0.95402, 0.95402, 0.95402, 0.95402, 0.95402,
  0.95402, 0.95402, 0.83089, 0.83089, 0.77596, 0.95402, 0.95402, 0.94143,
  0.98448, 0.98448, 0.98448, 0.98448, 0.98448, 0.8858, 0.78614, 0.83089,
  0.83089, 0.83089, 0.83089, 0.83089, 0.93285, 0.93285, 0.93285, 0.93285,
  0.93285, 0.97454, 0.97454, 0.97454, 0.97454, 1.04839, 1, 0.97454, 0.92916,
  0.92916, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.97454, 1.0976, 1.0976, 0.97454, 1.15373, 0.78032, 0.97454, 1.24822, 0.97454,
  0.97454, 0.86182, 0.94434, 0.86321, 0.8649, 0.94434, 1, 1, 1.45994, 1.17308,
  1.07403, 0.94565, 0.97454, 0.97454, 1.39016, 0.92481, 0.98205, 1, 0.91082,
  0.91082, 1.0949, 1.0949, 1.04839, 1, 0.92481, 1.15621, 0.87207, 0.87207,
  1.04839, 1, 1, 0.87207, 0.87207, 0.87207, 0.87207, 1.04839, 0.96927, 0.99821,
  1.42603, 1.04839, 1, 1.04315, 0.78383, 0.78383, 1, 1, 1.23393, 1, 1, 0.97454,
  0.67603, 0.67603, 0.95391, 0.97454, 0.94261, 1.05097, 1.34163, 1.04839, 1,
  0.81965, 1.24822, 1, 1, 0.96927, 1.04839, 0.93146, 0.87533, 0.87533, 0.87533,
  0.87533, 0.87533, 0.87533, 0.87533, 0.87533, 0.96927, 1, 0.73584, 0.87533, 1,
  0.89903, 1.01054, 0.87533, 1.04, 1.04, 1.17308, 1.17308, 1, 1.03342, 1.03342,
  0.93854, 1.14763, 0.95996, 0.95748, 1.06151, 1, 1.42603, 1, 0.96927, 1.09836,
  1, 0.96927, 0.96927, 1, 0.87709, 0.97454, 1.03809, 0.97454, 0.97454, 1,
  0.97454, 0.88409, 1.04839, 1, 1.17308, 1.24822, 0.9245, 0.9245, 0.94868,
  0.94868, 1.00176, 1.00176, 1.00176, 1.15296, 1, 1.04839, 1, 1.16484, 0.93146,
  0.93146, 0.93146, 0.93146, 0.93146, 1.12761, 0.93146, 1.14589, 1.96791, 1, 1,
  0.97622, 1.42603, 1.42603, 1, 1.16484, 1.2, 1.2, 1.2, 1.2, 0.93146, 1.08132,
  1.08132, 0.98047, 1.02148, 1, 0.98047, 1.16484, 1.16484, 1.0119, 1.04861, 1,
  0.78755, 1.17308, 1.24822, 1, 0.96927, 1.17308, 1.31868, 1.17984, 1.23736, 1,
  1.02989, 0.63218, 1, 1, 1.17308, 1.22135, 1.04724, 1.17308, 1, 1, 1.01054,
  1.01054, 0.9857, 1.01054, 1, 0.96927, 1, 1.24822, 1.01054, 1.0127, 1.06234,
  0.96225, 0.96225, 0.96225, 0.96225, 0.96225, 0.90171, 1.04839, 0.96225,
  0.96225, 0.96225, 1.0326, 1.0326, 0.96225, 0.96225, 0.96927, 1.09836, 1.13525,
  1.09836, 0.99862, 1.1781, 1.22326, 0.69825, 0.8761, 0.8761, 0.96225, 0.97454,
  0.85273, 0.91349, 0.91349, 1.083, 0.92586, 0.78383, 1.21191, 1.01473, 1.11826,
  0.8965, 1.17308, 1.24822, 0.91578, 1.0557, 0.97454, 0.77349, 0.70424, 1.05365,
  1.12317, 1.12317, 1.12317, 0.94945, 0.94945, 0.94945, 0.94945, 1.18414,
  1.06598, 1.06598, 1.18416, 1.06598, 1, 1.20808, 1, 0.97783, 1.04839, 1,
  0.79004, 0.79004, 0.79004, 0.79004, 0.79004, 1, 1.06483, 0.80597, 0.96927,
  1.01522, 1, 1, 0.94818, 0.93574, 0.96927, 1.42531, 1, 1.2886, 0.96927,
  0.86438, 1, 1.16344, 1.30679, 1.16344, 1.02759, 1, 1.05401, 0.97454, 0.96927,
  1.09836, 1.15222, 1.04839, 1, 0.81965, 0.77295, 0.87025, 0.87025, 0.87025,
  0.87025, 0.96927, 1.00351, 1.00351, 1.00351, 1.00351, 1.00351, 1.00351,
  1.00351, 1.00351, 0.74627, 0.75155, 0.98946, 1.20088, 1.09971, 1.09971, 1,
  1.09971, 0.86091, 1.16344, 1.04839, 0.78383, 0.74817, 1.03754, 0.87533,
  0.98705, 1, 1.20088, 1.24633, 1.07497, 1, 1, 1, 0.78032, 1, 1, 1.10742,
  1.10742, 1.00351, 1, 1.0675, 1.0675, 1.0675, 1.0675, 1.00351, 1.00351,
  0.94629, 0.9973, 0.9973, 0.9973, 0.9973, 0.9973, 0.91016, 1.02732, 0.96777,
  0.96777, 0.96777, 0.96777, 0.96927, 0.96777, 0.9043, 0.9043, 0.9043, 0.9043,
  0.96927, 0.95364,
];

export { SegoeuiItalicFactors };
