/* Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Factors to rescale LiberationSans-BoldItalic.ttf to have the same
// metrics as NimbusSans-BoldItalic.otf.
const HelveticaBoldItalicFactors = [
  0.76116, 1, 0.99977, 1, 1, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1.00001,
  0.99977, 0.99977, 0.99977, 0.91155, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 0.9998, 1.00001, 1.00001, 1,
  0.99977, 1.00026, 0.99977, 0.99973, 1.00026, 1.00022, 1.00022, 1.00022,
  1.00022, 1.00022, 1.00022, 0.99977, 0.99999, 0.99861, 0.99861, 1, 0.99977,
  0.99977, 1.0006, 0.99971, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 1.0006, 0.99969, 1.0006, 0.99973, 0.99973, 0.99977,
  0.99977, 0.99977, 1.00026, 1.00026, 1.00001, 1.00026, 1.00026, 1.00026,
  1.00026, 0.99999, 0.99999, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977,
  0.99977, 1.00022, 1, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022,
  1.00022, 1, 0.9998, 1.00022, 0.99972, 1.00022, 1.00022, 1.00022, 1.00001,
  0.99968, 1.00032, 1.00047, 1.00022, 0.99977, 0.99977, 0.99977, 0.99977,
  1.00001, 1.00001, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.6, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
  1.41144, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001, 0.99944, 1.00026,
  1.00026, 1.00026, 1.00026, 1.00026, 1.00022, 1.00001, 0.99977, 0.99977,
  0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1.00001,
  1.00001, 0.99981, 0.99977, 0.99977, 1.00001, 1.00016, 1.00016, 1.00016,
  1.00016, 1.00016, 1.00001, 0.99966, 1.00001, 1.00001, 1.00001, 1.00001,
  1.00001, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99998, 1, 0.99973, 0.99982, 0.99982, 1, 0.99977, 0.99999,
  0.99977, 1.00038, 0.99977, 1.00001, 1.00001, 0.99973, 1.00066, 0.99967,
  0.99967, 1.00041, 0.99998, 0.99999, 0.99977, 1.00022, 0.99967, 1.00001,
  0.99977, 1.00026, 0.99964, 1.00031, 1.00001, 0.99999, 0.99999, 1, 1.00023, 1,
  1, 0.99999, 1.00035, 1.00001, 0.99999, 0.99966, 1.00029, 1.00038, 1.00035,
  1.00001, 1.0006, 1.0006, 0.99973, 0.99978, 1.00001, 1.00057, 0.99989, 0.99964,
  0.99973, 0.99977, 0.99999, 1.00058, 0.99973, 0.99973, 0.99973, 0.99955,
  0.9995, 1.00026, 1.00026, 1.00032, 0.99989, 1.00034, 0.99999, 1.00026,
  1.00026, 1.00026, 0.99973, 0.45998, 0.99973, 1.00026, 0.99973, 1.00001,
  0.99999, 0.99982, 0.99994, 0.99996, 1, 1.00042, 1.00044, 1.00029, 1.00023,
  1.00044, 1.00026, 0.99949, 1.00002, 0.99973, 1.0006, 1.0006, 1.0006, 0.99975,
  1.00026, 1.00026, 1.00032, 0.99973, 0.99967, 1, 1.00026, 1, 0.99971, 0.99978,
  1, 0.99973, 0.99981, 0.99981, 0.99973, 0.99977, 1, 0.99973, 1.00031, 0.99973,
  0.99973, 1.00049, 1, 1.00098, 1, 1, 1, 1, 1.00003, 1.00003, 0.99959, 0.9999,
  0.99973, 1.00026, 1.0006, 1.00077, 1.00064, 1.41144, 0.99959, 0.99959,
  0.99998, 0.99998, 0.99998, 1, 1.00077, 0.99972, 0.99973, 0.99973, 0.99998, 1,
  1, 0.99973, 0.99973, 0.99973, 0.99973, 0.99998, 0.99973, 1.00063, 0.99999,
  0.99998, 1, 0.99962, 0.99998, 1.0006, 1, 1, 1.00025, 0.99973, 1, 1.00026,
  0.99973, 0.99973, 1.0044, 1.00026, 1.00024, 0.99942, 0.9995, 0.99998, 1,
  0.99998, 1.06409, 1.36625, 1.41144, 0.99973, 0.99998, 1.0006, 0.99973,
  0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99998, 1, 0.99973, 1, 0.99973, 1.00026, 0.99973, 1.00101, 1.00101, 1.00003,
  0.99915, 0.99984, 1.00026, 1.00026, 1.00026, 0.99998, 0.99999, 0.99998,
  0.99998, 1, 0.99999, 1, 0.99973, 1.00002, 0.99998, 0.99973, 0.99973, 0.99998,
  0.99973, 1.00026, 0.99973, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026,
  0.99998, 1, 1.00003, 1.00031, 0.99973, 0.99973, 0.99998, 0.99998, 1.00026,
  1.00026, 1.00026, 1.00042, 0.99999, 0.99998, 1, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 0.99973, 1.0006, 1.00015, 1.00027, 0.99999, 0.99999,
  0.99561, 0.99999, 0.99999, 0.99977, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006,
  1.0006, 1.0006, 1.0006, 0.99973, 1.00014, 0.99973, 0.99973, 1.0006, 1.0006,
  0.99973, 1.01011, 1.0006, 0.99999, 1.00003, 1.00031, 1.41144, 0.99973,
  1.00003, 1.0006, 0.99972, 1.0006, 1.40579, 0.99982, 0.60299, 1, 1, 1.00003,
  1.00106, 1.01322, 1.00003, 1, 1, 1.00026, 1.00026, 0.9993, 1.00026, 1.00026,
  0.99973, 1, 1.00031, 1.00026, 0.99973, 0.99973, 1.00026, 1.00026, 1.00026,
  1.00026, 1.00026, 1.00016, 0.99998, 1.00026, 1.00026, 1.00026, 0.99943,
  0.99943, 1.00026, 1.00026, 0.99973, 1.00002, 1.00002, 1.00002, 1.40483,
  0.99968, 0.99936, 1, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973, 0.99998,
  0.99998, 0.99972, 0.99982, 1.0006, 1, 1, 1.00055, 1.00012, 1.00003, 1.06409,
  1.20985, 1.00007, 1.00026, 1.00026, 1.00026, 0.99974, 1, 1, 1, 1.0006, 1.0006,
  1.0006, 1.0006, 1.00087, 0.99959, 0.99959, 1.00031, 0.99959, 0.99959, 1.00025,
  1.00003, 0.99923, 0.99998, 1.41144, 0.99973, 0.99973, 0.99973, 0.99973,
  0.99973, 0.99973, 0.99999, 0.99973, 0.99973, 1.00002, 0.99998, 1.40579,
  1.00064, 1, 0.99973, 1.0006, 1, 0.99953, 0.99973, 1.39713, 1.00054, 0.99998,
  1.00076, 0.99998, 0.8121, 0.99998, 1.00069, 1.00026, 0.99973, 1.00002,
  1.00002, 0.99998, 1, 0.99998, 1, 1.00026, 1.00026, 1.00026, 1.00026, 0.99973,
  1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026, 1.00026,
  0.99973, 0.99955, 1.0006, 0.99998, 1, 1, 1, 1, 1.00026, 0.99998, 0.99998, 1,
  1.00001, 0.99967, 0.99973, 0.98685, 1, 1, 0.99998, 1, 0.99991, 1, 1.66475,
  1.0006, 1, 1, 1, 1, 1.00026, 1.41144, 0.99948, 0.99948, 0.99948, 0.99948,
  1.00026, 1.00026, 0.99973, 1.00022, 1.00022, 1.00022, 1.00022, 1.00022,
  0.99973, 1.00065, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 1, 1,
  1, 1, 0.99973, 1.00061,
];

export { HelveticaBoldItalicFactors };
